$( document ).on('turbolinks:load', function() {
  $(document).on("click", ".copy-partner-url", function(event) {
    event.preventDefault();
    currentTarget = event.currentTarget;

    /* Get the text field */
    var copyText = currentTarget.parentElement.querySelector('.partner-url');

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    document.execCommand("copy");

    /* Alert the copied text */
    $("#copy-partner-url-copied-message").html("Copied");
  });

  $(document).on("click", ".partner-path-submit", function(event) {
    console.log('show modal');
    $('#partner-url-modal').modal('show');
  });
});
