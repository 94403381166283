/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

document.addEventListener("page:load ready", function() {
  initWiceGrid();
});

document.addEventListener("turbolinks:load", function() {
  initWiceGrid();
});

const globalVarForAllGrids = 'wiceGrids';

var initWiceGrid = function() {
  $(".wice-grid-container").each(function(index, wiceGridContainer) {
    const gridName = wiceGridContainer.id;
    const dataDiv = $(".wg-data", wiceGridContainer);

    const processorInitializerArguments = dataDiv.data("processor-initializer-arguments");

    const filterDeclarations = dataDiv.data("filter-declarations");

    focusElementIfNeeded(dataDiv.data("foc"));

    const gridProcessor = new WiceGridProcessor(gridName,
      processorInitializerArguments[0], processorInitializerArguments[1],
      processorInitializerArguments[2], processorInitializerArguments[3],
      processorInitializerArguments[4], processorInitializerArguments[5]);

    for (let filterDeclaration of Array.from(filterDeclarations)) {
      (function(filterDeclaration) {
        if (filterDeclaration != null) {
          return gridProcessor.register({
            filterName : filterDeclaration.filterName,
            detached    : filterDeclaration.detached,
            templates   : filterDeclaration.declaration.templates,
            ids         : filterDeclaration.declaration.ids
          });
        }
      })(filterDeclaration);
    }

    if (!window[globalVarForAllGrids]) {
      window[globalVarForAllGrids] = {};
    }

    window[globalVarForAllGrids][gridName] = gridProcessor;

    // setting up stuff for in the context of each grid
    setupSubmitReset(wiceGridContainer, gridProcessor);
    setupCsvExport(wiceGridContainer, gridProcessor);
    setupHidingShowingOfFilterRow(wiceGridContainer);
    setupShowingAllRecords(wiceGridContainer, gridProcessor);
    setupMultiSelectToggle(wiceGridContainer);
    setupAutoreloadsForInternalFilters(wiceGridContainer, gridProcessor);
    return setupBulkToggleForActionColumn(wiceGridContainer);
  });

  setupAutoreloadsForExternalFilters();
  setupExternalSubmitReset();
  setupExternalCsvExport();
  setupDatepicker();

  // for all grids on oage because it does not matter which grid it is
  return setupMultiSelectToggle($('.wg-detached-filter'));
};



const moveDateBoundIfInvalidPeriod = function(dataFieldNameWithTheOtherDatepicker, datepickerHiddenField, selectedDate, dateFormat, predicate) {
  let _datepickerId, datepickerId, theOtherDate, theOtherDatepicker;
  if ((datepickerId = datepickerHiddenField.data(dataFieldNameWithTheOtherDatepicker)) &&
    (theOtherDatepicker = $(_datepickerId = "#" + datepickerId)) &&
    (theOtherDate = theOtherDatepicker.datepicker('getDate')) &&

    predicate(theOtherDate, selectedDate)) {
      theOtherDatepicker.datepicker("setDate", selectedDate);
      return theOtherDatepicker.next().next().html($.datepicker.formatDate(dateFormat, selectedDate));
    }
};


var setupDatepicker = function() {
  if ($('.date-filter.wg-jquery-datepicker').length !== 0) {
    setupJqueryUiDatepicker();
  }

  if ($('.date-filter.wg-bootstrap-datepicker').length !== 0) {
    return setupBootstrapDatepicker();
  }
};


var setupBootstrapDatepicker = function() {
  // check for bootstrap datepicker
  if (!$.fn.datepicker) {
    alert(`Seems like you do not have Bootstrap datepicker gem (https://github.com/Nerian/bootstrap-datepicker-rails)
installed. Either install it pick another filter with :filter_type.\
`
    );
    return;
  }

  return $('.date-filter.wg-bootstrap-datepicker input:text[data-provide=datepicker]').each((index, dateField) => $(dateField).datepicker().on('hide', function(event) {
    const $self = $(event.currentTarget);

    const eventToTriggerOnChange = $self.data('close-calendar-event-name');

    if (eventToTriggerOnChange) {
      return $self.trigger(eventToTriggerOnChange);

    } else if ($self.attr('id').split('_').pop() === 'fr') {
      const $to = $self.parent().next().find('input:text.check-for-bsdatepicker');
      if ($to.length > 0) {
        return $to.datepicker('show');
      }
    }
  }));
};


var setupJqueryUiDatepicker = function() {
  // check jquery ui datepickeer
  let locale;
  if (!$.datepicker) {
    alert(`Seems like you do not have jQuery datepicker (http://jqueryui.com/demos/datepicker/)
installed. Either install it pick another filter with :filter_type.\
`
    );
  }
  // setting up the locale for datepicker
  if (locale = $('.date-filter.wg-jquery-datepicker input[type=hidden]').data('locale')) {
    $.datepicker.setDefaults($.datepicker.regional[locale]);
  }


  return $('.date-filter.wg-jquery-datepicker .date-label').each(function(index, removeLink) {
    const datepickerHiddenField  = $('#' + $(removeLink).data('dom-id'));

    const eventToTriggerOnChange = datepickerHiddenField.data('close-calendar-event-name');

    // setting up the remove link for datepicker
    $(removeLink).click(function(event) {
      $(this).html('');
      datepickerHiddenField.val('');
      if (eventToTriggerOnChange) {
        datepickerHiddenField.trigger(eventToTriggerOnChange);
      }
      event.preventDefault();
      return false;
    });
    const that = this;

    const dateFormat = datepickerHiddenField.data('date-format');

    const yearRange = datepickerHiddenField.data('date-year-range');

    const labelText = datepickerHiddenField.data('button-text');

    // datepicker constructor
    datepickerHiddenField.datepicker({
      firstDay:        1,
      dateFormat,
      changeMonth:     true,
      changeYear:      true,
      yearRange,

      onSelect(dateText, inst) {

        const selectedDate = $(this).datepicker("getDate");

        moveDateBoundIfInvalidPeriod(
          'the-other-datepicker-id-to',
          datepickerHiddenField,
          selectedDate,
          dateFormat,
          (theOther, selected) => theOther < selected);

        moveDateBoundIfInvalidPeriod(
          'the-other-datepicker-id-from',
          datepickerHiddenField,
          selectedDate,
          dateFormat,
          (theOther, selected) => theOther > selected);

        $(that).html(dateText);
        if (eventToTriggerOnChange) {
          return datepickerHiddenField.trigger(eventToTriggerOnChange);
        }
      }
    });

    const datepickerContainer = datepickerHiddenField.parent();

    $(removeLink).before(` <i class=\"fa fa-calendar ui-datepicker-trigger\" title=\"${labelText}\" ></i> `);

    const newlyAdded = $('.fa-calendar', datepickerContainer);

    return newlyAdded.click(() => datepickerHiddenField.datepicker("show"));
  });
};


// hiding and showing the row with filters
var setupHidingShowingOfFilterRow = function(wiceGridContainer) {
  const hideFilter = '.wg-hide-filter';
  const showFilter = '.wg-show-filter';
  const filterRow = '.wg-filter-row';

  $(hideFilter, wiceGridContainer).click(function() {
    $(this).hide();
    $(showFilter, wiceGridContainer).show();
    return $(filterRow, wiceGridContainer).hide();
  });

  return $(showFilter, wiceGridContainer).click(function() {
    $(this).hide();
    $(hideFilter, wiceGridContainer).show();
    return $(filterRow, wiceGridContainer).show();
  });
};


var setupCsvExport = (wiceGridContainer, gridProcessor) => $('.export-to-csv-button', wiceGridContainer).click(() => gridProcessor.exportToCsv());


// trigger submit/reset from within the grid
var setupSubmitReset = function(wiceGridContainer, gridProcessor) {
  $('.submit', wiceGridContainer).click(() => gridProcessor.process());

  $('.reset', wiceGridContainer).click(() => gridProcessor.reset());

  return $('.wg-filter-row input[type=text], .wg-filter-row input:text[data-provide=datepicker]', wiceGridContainer).keydown(function(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      return gridProcessor.process();
    }
  });
};

const SetEnd = function(txt) {
  if (txt.createTextRange) {
    //IE
    const FieldRange = txt.createTextRange();
    FieldRange.moveStart('character', txt.value.length);
    FieldRange.collapse();
    FieldRange.select();
  } else {
    //Firefox and Opera
    txt.focus();
    const {
      length
    } = txt.value;
    txt.setSelectionRange(length, length);
  }
};

var focusElementIfNeeded = function(focusId) {
  let elToFocus;
  const elements = $('#' + focusId);
  if (elToFocus = elements[0]) {
    return SetEnd(elToFocus);
  }
};

// autoreload for internal filters
var setupAutoreloadsForInternalFilters = function(wiceGridContainer, gridProcessor) {
  $('select.auto-reload, input.native-datepicker.auto-reload', wiceGridContainer).change(() => gridProcessor.process());

  $('input.auto-reload', wiceGridContainer).keyup(function(event){
    if (isKeySignificant(event.which)) {
      return gridProcessor.setProcessTimer(this.id);
    }
  });

  $('input.negation-checkbox.auto-reload', wiceGridContainer).click(() => gridProcessor.process());

  return $(document).bind('wg:calendarChanged_' + gridProcessor.name, () => gridProcessor.process());
};



var isKeySignificant = (keyCode, func) => [37, 38, 39, 40, 9, 27].indexOf(keyCode) === -1;

// autoreload for internal filters
var setupAutoreloadsForExternalFilters =  () => $('.wg-detached-filter').each(function(index, detachedFilterContainer) {
  const gridProcessor = getGridProcessorForElement(detachedFilterContainer);
  if (gridProcessor) {
    $('select.auto-reload, input.native-datepicker.auto-reload', detachedFilterContainer).change(() => gridProcessor.process());

    $('input.auto-reload', detachedFilterContainer).keyup(function(event){
      if (isKeySignificant(event.which)) {
        return gridProcessor.setProcessTimer(this.id);
      }
    });

    return $('input.negation-checkbox.auto-reload', detachedFilterContainer).click(() => gridProcessor.process());
  }
});


// trigger the all records mode
var setupShowingAllRecords = (wiceGridContainer, gridProcessor) => $('.wg-show-all-link, .wg-back-to-pagination-link', wiceGridContainer).click(function(event) {
  event.preventDefault();
  const gridState = $(this).data("grid-state");
  const confirmationMessage = $(this).data("confim-message");
  const reloadGrid = () => gridProcessor.reloadPageForGivenGridState(gridState);
  if (confirmationMessage) {
    if (confirm(confirmationMessage)) {
      return reloadGrid();
    }
  } else {
    return reloadGrid();
  }
});

// dropdown filter multiselect
var setupMultiSelectToggle = function(wiceGridContainer){
  $('.expand-multi-select-icon', wiceGridContainer).click(function() {
    $(this).prev().each((index, select) => select.multiple = true);
    $(this).next().show();
    return $(this).hide();
  });

  return $('.collapse-multi-select-icon', wiceGridContainer).click(function() {
    $(this).prev().prev().each((index, select) => select.multiple = false);
    $(this).prev().show();
    return $(this).hide();
  });
};


var setupBulkToggleForActionColumn = function(wiceGridContainer) {
  $('.select-all', wiceGridContainer).click(() => $('.sel input', wiceGridContainer).prop('checked', true).trigger('change'));

  $('.deselect-all', wiceGridContainer).click(() => $('.sel input', wiceGridContainer).prop('checked', false).trigger('change'));

  return $('.wg-select-all', wiceGridContainer).click(function() {
    return $('.sel input', wiceGridContainer).prop('checked', $(this).prop('checked')).trigger('change');
  });
};


var getGridProcessorForElement = function(element) {
  const gridName = $(element).data('grid-name');
  if (gridName && window[globalVarForAllGrids]) {
    return window[globalVarForAllGrids][gridName];
  } else {
    return null;
  }
};


var setupExternalCsvExport =  () => $(".wg-external-csv-export-button").each(function(index, externalCsvExportButton) {
  const gridProcessor = getGridProcessorForElement(externalCsvExportButton);
  if (gridProcessor) {
    return $(externalCsvExportButton).click(event => gridProcessor.exportToCsv());
  }
});


var setupExternalSubmitReset =  function() {

  $(".wg-external-submit-button").each(function(index, externalSubmitButton) {
    const gridProcessor = getGridProcessorForElement(externalSubmitButton);
    if (gridProcessor) {
      return $(externalSubmitButton).click(function(event) {
        gridProcessor.process();
        event.preventDefault();
        return false;
      });
    }
  });

  $(".wg-external-reset-button").each(function(index, externalResetButton) {
    const gridProcessor = getGridProcessorForElement(externalResetButton);
    if (gridProcessor) {
      return $(externalResetButton).click(function(event) {
        gridProcessor.reset();
        event.preventDefault();
        return false;
      });
    }
  });


  return $('.wg-detached-filter').each(function(index, detachedFilterContainer) {
    const gridProcessor = getGridProcessorForElement(detachedFilterContainer);
    if (gridProcessor) {
      return $('input[type=text], input:text[data-provide=datepicker]', this).keydown(function(event) {
        if (event.keyCode === 13) {
          gridProcessor.process();
          event.preventDefault();
          return false;
        }
      });
    }
  });
};

window['getGridProcessorForElement'] = getGridProcessorForElement;
window['initWiceGrid'] = initWiceGrid;
