/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('page:load ready', () => savedQueriesInit());


var savedQueriesInit = function() {

  $('.wice-grid-save-query-field').keydown(function(event) {
    if (event.keyCode === 13) {
      return saveQuery($(this).next(), event);
    }
  });

  $(".wice-grid-save-query-button").click(function(event) {
    return saveQuery(this, event);
  });

  $(".wice-grid-delete-query").click(function(event) {
    return deleteQuery(this, event);
  });

  return $(".wice-grid-query-load-link").click(function(event) {
    return loadQuery(this, event);
  });
};

var loadQuery = function(loadLink, event) {
  let gridProcessor;
  if (gridProcessor = window.getGridProcessorForElement(loadLink)) {
    const queryId = $(loadLink).data('query-id');

    const request = gridProcessor.appendToUrl(
      gridProcessor.buildUrlWithParams(),
      gridProcessor.parameterNameForQueryLoading +  encodeURIComponent(queryId)
    );

    gridProcessor.visit(request);
  }

  event.preventDefault();
  event.stopPropagation();
  return false;
};


var deleteQuery = function(deleteQueryButton, event) {

  let gridProcessor;
  const confirmation = $(deleteQueryButton).data('wg-confirm');

  const invokeConfirmation = confirmation ?
    () => confirm(confirmation)
  :
    () => true;

  if (invokeConfirmation() && (gridProcessor = window.getGridProcessorForElement(deleteQueryButton))) {

    jQuery.ajax({
      url: $(deleteQueryButton).attr('href'),
      async: true,
      dataType: 'json',
      success(data, textStatus, jqXHR) {
        return onChangeToQueryList(data, gridProcessor.name);
      },
      type: 'POST'
    });
  }
  event.preventDefault();
  event.stopPropagation();
  return false;
};

var saveQuery = function(saveQueryButton, event) {
  let gridProcessor;
  if (gridProcessor = window.getGridProcessorForElement(saveQueryButton)) {
    const _saveQueryButton = $(saveQueryButton);
    const basePathToQueryController = _saveQueryButton.data('base-path-to-query-controller');
    const gridState                 = _saveQueryButton.data('parameters');
    const inputIds                  = _saveQueryButton.data('ids');
    const inputField                = _saveQueryButton.prev();

    if (inputIds instanceof Array) {
      inputIds.each(domId => gridState.push(['extra[' + domId + ']', $('#'+ domId).val()]));
    }

    const queryName = inputField.val();

    const requestPath = gridProcessor.gridStateToRequest(gridState);

    jQuery.ajax({
      url: basePathToQueryController,
      async: true,
      data: requestPath + '&query_name=' + encodeURIComponent(queryName),
      dataType: 'json',
      success(data, textStatus, jqXHR) {
        return onChangeToQueryList(data, gridProcessor.name, queryName, inputField);
      },
      type: 'POST'
    });

    event.preventDefault();
    return false;
  }
};

var onChangeToQueryList = function(data, gridName, queryName, inputField) {
  let errorMessages;
  const notificationMessagesDomId = `#${gridName}_notification_messages`;
  const gridTitleId  = `#${gridName}_title`;
  const queryListId  = `#${gridName}_query_list`;
  if (queryName) { inputField.val(''); }
  if (errorMessages = data['error_messages']) {
    return $(notificationMessagesDomId).text(errorMessages);
  } else {
    let notificationMessages;
    if (notificationMessages = data['notification_messages']) {
      $(notificationMessagesDomId).text(notificationMessages);
    }
    if (queryName) { $(gridTitleId).html(`<h3>${queryName}</h3>`); }
    $(queryListId).replaceWith(data['query_list']);
    if (jQuery.ui) { $(queryListId).effect('highlight'); }

    $(".wice-grid-delete-query", $(queryListId)).click(function(event) {
      return deleteQuery(this, event);
    });

    return $(".wice-grid-query-load-link", $(queryListId)).click(function(event) {
      return loadQuery(this, event);
    });
  }
};
