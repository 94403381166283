$( document ).on('turbolinks:load', function() {
  
  $(document).on("click", ".copy-image-tag", function(event) {
    event.preventDefault();
    currentTarget = event.currentTarget;

    /* Get the text field */
    var copyText = currentTarget.parentElement.querySelector('.goal-image-tag');

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    document.execCommand("copy");

    /* Alert the copied text */
    alert("Copied the text: " + copyText.value);
  });
});
