/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS202: Simplify dynamic range loops
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
class WiceGridProcessor {
  static initClass() {
  
    this;
  }
  constructor(name, baseRequestForFilter, baseLinkForShowAllRecords, linkForExport, parameterNameForQueryLoading, parameterNameForFocus, environment) {
    this.name = name;
    this.baseRequestForFilter = baseRequestForFilter;
    this.baseLinkForShowAllRecords = baseLinkForShowAllRecords;
    this.linkForExport = linkForExport;
    this.parameterNameForQueryLoading = parameterNameForQueryLoading;
    this.parameterNameForFocus = parameterNameForFocus;
    this.environment = environment;
    this.filterDeclarations = new Array();
    this.checkIfJsFrameworkIsLoaded();
  }

  checkIfJsFrameworkIsLoaded() {
    if (!jQuery) {
      return alert("jQuery not loaded, WiceGrid cannot proceed!");
    }
  }

  toString() {
    return "<WiceGridProcessor instance for grid '" + this.name + "'>";
  }


  process(domIdToFocus){
    return this.visit(this.buildUrlWithParams(domIdToFocus));
  }

  visit(path) {
    if (typeof Turbolinks !== 'undefined' && Turbolinks !== null) {
      return Turbolinks.visit(path);
    } else {
      return window.location = path;
    }
  }

  setProcessTimer(domIdToFocus){

    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }

    const processor = this;

    return this.timer = setTimeout(
      () => processor.process(domIdToFocus),
      1000
    );
  }

  reloadPageForGivenGridState(gridState){
    const requestPath = this.gridStateToRequest(gridState);
    return this.visit(this.appendToUrl(this.baseLinkForShowAllRecords, requestPath));
  }


  gridStateToRequest(gridState){
    return jQuery.map(
      gridState,
      pair => encodeURIComponent(pair[0]) + '=' + encodeURIComponent(pair[1])).join('&');
  }


  appendToUrl(url, str){

    const sep = url.indexOf('?') !== -1 ?
      /[&\?]$/.exec(url) ?
        ''
      :
        '&'
    :
      '?';
    return url + sep + str;
  }

  buildUrlWithParams(domIdToFocus){
    const results = new Array();
    jQuery.each(
      this.filterDeclarations,
      (i, filterDeclaration)=> {
        const param = this.readValuesAndFormQueryString(filterDeclaration.filterName, filterDeclaration.detached, filterDeclaration.templates, filterDeclaration.ids);

        if (param && (param !== '')) {
          return results.push(param);
        }
    });

    let res = this.baseRequestForFilter;
    if  (results.length !== 0) {
      const allFilterParams = results.join('&');
      res = this.appendToUrl(res, allFilterParams);
    }

    if (domIdToFocus) {
      res = this.appendToUrl(res, this.parameterNameForFocus + domIdToFocus);
    }

    return res;
  }



  reset() {
    return this.visit(this.baseRequestForFilter);
  }


  exportToCsv() {
    return this.visit(this.linkForExport);
  }


  register(func){
    return this.filterDeclarations.push(func);
  }


  readValuesAndFormQueryString(filterName, detached, templates, ids){
    const res = new Array();

    for (let i = 0, end = templates.length-1, asc = 0 <= end; asc ? i <= end : i >= end; asc ? i++ : i--) {

      var val;
      if ($(ids[i]) === null) {
        if (this.environment === "development") {
          let message = 'WiceGrid: Error reading state of filter "' + filterName + '". No DOM element with id "' + ids[i] + '" found.';
          if (detached) {
            message += 'You have declared "' + filterName + '" as a detached filter but have not output it anywhere in the template. Read documentation about detached filters.';
          }

          alert(message);
        }

        return '';
      }

      const el = $('#' + ids[i]);

      if (el[0] && (el[0].type === 'checkbox')) {
        if (el[0].checked) {
          val = 1;
        }
      } else {
        val = el.val();
      }

      if (val instanceof Array) {
        for (let j = 0, end1 = val.length-1, asc1 = 0 <= end1; asc1 ? j <= end1 : j >= end1; asc1 ? j++ : j--) {
          if (val[j] && (val[j] !== "")) {
            res.push(templates[i] + encodeURIComponent(val[j]));
          }
        }

      } else if (val &&  (val !== '')) {
        res.push(templates[i]  + encodeURIComponent(val));
      }
    }


    return res.join('&');
  }
}
WiceGridProcessor.initClass();


WiceGridProcessor._version = '3.4';

window['WiceGridProcessor'] = WiceGridProcessor;
