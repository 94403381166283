
$( document ).on('turbolinks:load', function() {
  
  $(document).on("change", ".datepicker", function(event) {
    event.preventDefault();
    submitParentForm(event);
  });

  $(document).on("change", "#group_by", function(event) {
    event.preventDefault();
    submitParentForm(event);
  });

  function submitParentForm(event){
    currentTarget = event.currentTarget;

    console.log(currentTarget);

    /* Get the enclosing form */
    form = $(currentTarget).parents('form');

    console.log(form);

    form.submit();
  }
});
