// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("bootstrap")
require("./jquery.dataTables.min")
require("./dataTables.bootstrap4.min")
require("./scripts")
require("./grid")
require("./iframeResizer.contentWindow")
require("./goals")
require("./clicks")
require("./wice_grid/wice_grid")
require('./nested-forms/addFields')
require('./nested-forms/removeFields')
require("./paths")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "../stylesheets/application";
import "../stylesheets/scss/styles";
import "../stylesheets/wice_grid";
import "../stylesheets/dataTables.bootstrap4.min";
import "@fortawesome/fontawesome-free/js/all";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";


document.addEventListener("turbolinks:load", function() {
  flatpickr(".datepicker", {})
})
